export default function useCalculator() {
  function getTagCounts(differentials) {
    const tagCounts = {};

    differentials.forEach(diagnosis => {
      console.log(diagnosis.tags);
      if (tagCounts[diagnosis.tags]) {
        tagCounts[diagnosis.tags]++;
      } else {
        tagCounts[diagnosis.tags] = 1;
      }
    });

    return tagCounts;
  }

  function checkProbability(symptoms, dxObj) {
    let score = 0;

    const dxSymptoms = dxObj.symptoms.map(s => s.medical_term);
    const dxAssociated = dxObj.associated_symptoms
      ? dxObj.associated_symptoms.map(s => s.medical_term)
      : [];
    const dxMinors = dxObj.minor ? dxObj.minor.map(s => s.medical_term) : [];

    symptoms.forEach(symptom => {
      if (dxSymptoms.includes(symptom)) {
        score += 2;
      } else if (dxAssociated.includes(symptom)) {
        score += 1;
      } else if (dxMinors.includes(symptom)) {
        score += 0.3;
      }
    });

    return score;
  }

  function getDifferentials(allDiagnoses, symptoms) {
    if (symptoms.length === 0) return [];
    let diagnoses = [];
    allDiagnoses.forEach(dxObj => {
      let score = checkProbability(symptoms, dxObj);
      if (score >= 2) {
        diagnoses.push({
          id: dxObj.id,
          diagnosis: dxObj.diagnosis,
          symptoms: dxObj.symptoms,
          tags: dxObj.tags,
          score
        });
      }
    });

    diagnoses.sort((a, b) => {
      // If scores are the same, randomize order
      if (a.score === b.score) {
        return 0.5 - Math.random();
      }
      // Otherwise, sort by score
      return b.score - a.score;
    });

    return diagnoses;
  }

  return { checkProbability, getDifferentials, getTagCounts };
}
