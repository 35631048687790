import axios from "axios";

// const baseUrl = "http://localhost:3001";
// const baseUrl = "https://www.mafishi.io"; // Nginx proxy endpoint
const isDevelopment = process.env.NODE_ENV === "development";
const baseUrl = isDevelopment ? "http://localhost:3007" : "";

export default function useFetch() {
  const wrapPromise = promise => {
    let status = "pending";
    let result = "";
    let suspender = promise.then(
      r => {
        status = "success";
        result = r;
      },
      e => {
        status = "error";
        result = e;
      }
    );

    return {
      read() {
        if (status === "pending") {
          throw suspender;
        } else if (status === "error") {
          throw result;
        }

        return result;
      }
    };
  };

  async function fetchSymptoms() {
    let symptoms;
    await axios
      .get(`${baseUrl}/api/symptoms`)
      .then(response => (symptoms = response.data.symptoms))
      .catch(error => console.log(error));

    return symptoms;
  }

  const createResource = () => {
    const cache = {
      symptoms: null,
      diagnoses: null
    };

    return {
      symptoms: {
        read() {
          if (cache.symptoms === null) {
            cache.symptoms = wrapPromise(fetchSymptoms());
          }
          return cache.symptoms.read();
        }
      },
      diagnoses: {
        read() {
          if (cache.diagnoses === null) {
            cache.diagnoses = wrapPromise(fetchDiagnoses());
          }
          return cache.diagnoses.read();
        }
      }
    };
  };

  async function fetchDiagnoses() {
    let diagnoses;
    await axios
      .get(`${baseUrl}/api/diagnoses`)
      .then(response => (diagnoses = response.data.diagnoses))
      .catch(error => console.log(error));

    return diagnoses;
  }

  async function fetchDiagnosis(diagnosisId) {
    let dx;
    await axios
      .get(`${baseUrl}/api/diagnoses/${diagnosisId}`)
      .then(response => {
        dx = response.data.diagnosis;
      })
      .catch(error => {
        console.log(error);
      });
    return dx;
  }

  async function fetchSymptom(symptomId) {
    let sx;
    await axios
      .get(`${baseUrl}/api/symptoms/${symptomId}`)
      .then(response => {
        sx = response.data.symptom;
      })
      .catch(error => {
        console.log(error);
      });
    return sx;
  }

  return { fetchDiagnoses, fetchDiagnosis, fetchSymptom, fetchSymptoms, createResource };
}
