import { Link as RouterLink, useMatch } from "react-router-dom";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";
import HealingIcon from "@mui/icons-material/Healing";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/system";
import { useState } from "react";

const StyledRouterLink = styled(RouterLink)({
  textDecoration: "none",
  color: "inherit"
});

const Navbar = () => {
  const navLinks = [
    { title: "Differentials", path: "/", icon: <ListAltIcon /> },
    { title: "Diagnoses", path: "/diagnoses", icon: <ListAltIcon /> },
    { title: "Symptoms", path: "/symptoms", icon: <HealingIcon /> },
    { title: "Diagnosis", path: "/diagnoses/add", icon: <AddCircleIcon /> },
    { title: "Symptom", path: "/symptoms/add", icon: <AddCircleIcon /> }
    // { title: "Terms", path: "/terms", icon: <InfoIcon /> }
  ];

  const isLinkActive = path => {
    return useMatch(path);
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const theme = useTheme();

  const MobileNavList = () => (
    <List>
      {navLinks.map(link => (
        <ListItem
          button
          key={link.title}
          component={StyledRouterLink}
          to={link.path}
          onClick={toggleDrawer}
          sx={{
            backgroundColor: isLinkActive(link.path) ? "gray" : "white",
            marginBottom: theme.spacing(2)
          }}>
          <ListItemIcon>{link.icon}</ListItemIcon>
          <ListItemText primary={link.title} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <AppBar
        position="static"
        elevation={0}
        color="transparent"
        sx={{
          mb: "20px",
          boxShadow: "none"
        }}>
        <Toolbar sx={{ px: 0 }}>
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              color: "black"
            }}
            onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <Typography variant="overline" sx={{ flexGrow: 1, color: "black" }}>
            Symptom Overflow
          </Typography>
          <Box
            display={{ xs: "none", sm: "none", md: "flex" }}
            sx={{
              flexDirection: "row",
              alignItems: "center"
            }}>
            {navLinks.map(link => (
              <Box
                key={link.title}
                component={StyledRouterLink}
                to={link.path}
                sx={{
                  color: isLinkActive(link.path) ? "dodgerblue" : "dimgray",
                  display: "flex",
                  alignItems: "center",
                  padding: theme.spacing(0, 2),
                  "&:visited": {
                    color: "charcoal"
                  }
                }}>
                {link.icon}
                <Typography variant="body2">{link.title}</Typography>
              </Box>
            ))}
          </Box>
          <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
            <MobileNavList />
          </Drawer>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
