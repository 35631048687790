import {
  Autocomplete,
  TextField,
  FormControl,
  Chip,
  Stack,
  Typography,
  Box,
  IconButton
} from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const HomeSymptomSelector = ({
  symptoms,
  symptomList,
  jsonSymptoms,
  updateFields,
  id,
  label,
  didSubmit
}) => {
  const [symptomValues, setSymptomValues] = useState([]);
  const [textInput, setTextInput] = useState("");

  useEffect(() => {
    if (symptomValues.length > 0 && !didSubmit) {
      const sxObjects = symptomValues.map(medicalTerm => {
        return jsonSymptoms.find(s => s.medical_term === medicalTerm);
      });
      updateFields({ [id]: sxObjects });
    }
  }, [symptomValues]);

  useEffect(() => {
    if (didSubmit) {
      setSymptomValues([]);
    }
  }, [didSubmit]);

  const filterOptions = (options, { inputValue }) => {
    if (inputValue.length >= 2) {
      return options
        .filter(option => option.toLowerCase().includes(inputValue.toLowerCase()))
        .slice(0, 5);
    }
    return [];
  };

  const handleRemoveSymptom = symptom => {
    const updatedSymptomValues = symptomValues.filter(value => value !== symptom);
    setSymptomValues(updatedSymptomValues);
    const sxObjects = updatedSymptomValues.map(medicalTerm => {
      return jsonSymptoms.find(s => s.medical_term === medicalTerm);
    });
    updateFields({ [id]: sxObjects });
  };

  return (
    <Box>
      <FormControl sx={{ width: "45%" }}>
        <Autocomplete
          id={id}
          options={symptomList}
          getOptionLabel={option => option}
          noOptionsText={
            textInput.length >= 2 ? "No matching options" : "Enter a search term to see suggestions"
          }
          filterOptions={filterOptions}
          value={textInput || null} // Set to null if textInput is an empty string
          isOptionEqualToValue={(option, value) => option === value} // Custom equality test
          onChange={(e, newValue) => {
            if (newValue && !symptomValues.includes(newValue)) {
              const updatedSymptomValues = [...symptomValues, newValue];
              setSymptomValues(updatedSymptomValues);
              const sxObjects = updatedSymptomValues.map(medicalTerm => {
                return jsonSymptoms.find(s => s.medical_term === medicalTerm);
              });
              updateFields({ [id]: sxObjects });
            }
            setTextInput(""); // Clear the text input
          }}
          onInputChange={(event, newInputValue) => {
            setTextInput(newInputValue);
          }}
          renderInput={params => (
            <TextField {...params} variant="standard" label={label} placeholder="Symptoms" />
          )}
        />
      </FormControl>
      <Stack
        direction="row"
        spacing={1}
        sx={{ mt: "25px", flexWrap: "wrap" }}
        alignItems="flex-start">
        {symptomValues.map(symptom => (
          <Chip
            key={symptom}
            label={symptom}
            onDelete={() => handleRemoveSymptom(symptom)}
            deleteIcon={<CloseIcon />}
            sx={{ mb: 1, ml: 0 }}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default HomeSymptomSelector;
