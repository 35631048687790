import { useState, useEffect } from "react";
import {
  Box,
  LinearProgress,
  Typography,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  List,
  ListItem
} from "@mui/material";

import useCalculator from "../../hooks/useCalculator";

const Differentials = ({
  symptoms,
  flatDiagnoses,
  differentialsCount,
  setDifferentialsCount,
  differentials,
  setDifferentials
}) => {
  const { getDifferentials } = useCalculator();

  let maxScore = null;

  useEffect(() => {
    let diagnoses = getDifferentials(flatDiagnoses, symptoms, differentialsCount);
    console.log(diagnoses);
    setDifferentials(diagnoses.slice(0, 15));
    maxScore = diagnoses[0]?.score; // update the previous score
  }, [symptoms, differentialsCount]);

  function symptomsForDiagnosis(dx) {
    const matches = dx.symptoms
      .filter(s => symptoms.includes(s.medical_term))
      .map(s => s.medical_term);
    return matches.join(", ").toLowerCase();
  }

  // const renderBar = (index, score) => {
  //   if (score >= maxScore) maxScore = score;
  //   const barColor = index === 0 ? "#1976d2" : "#b3d4fc";
  //   const barValue =
  //     score >= maxScore ? 100 : ((differentialsCount - index) / differentialsCount) * 100;
  //   return (
  //     <LinearProgress
  //       variant="determinate"
  //       value={barValue}
  //       style={{ backgroundColor: barColor, height: "20px", marginBottom: "10px" }}
  //     />
  //   );
  // };

  return (
    <Box sx={{ width: "100%", maxWidth: "85%", margin: "20px" }}>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: "15px" }}>
        <Typography variant="h5" sx={{ fontFamily: "inherit", color: "maroon" }}>
          Differential Diagnoses
        </Typography>
        {/* <ToggleButtonGroup
          value={differentialsCount}
          size="small"
          exclusive
          onChange={(event, newCount) => newCount && setDifferentialsCount(newCount)}
          aria-label="differentials count">
          <ToggleButton value={5} aria-label="Top 5">
            Top 5
          </ToggleButton>
          <ToggleButton value={10} aria-label="Top 10">
            Top 10
          </ToggleButton>
          <ToggleButton value={15} aria-label="Top 15">
            Top 15
          </ToggleButton>
        </ToggleButtonGroup> */}
      </Stack>
      <ol>
        {differentials &&
          differentials.map((dx, index) => (
            <ListItem key={dx.diagnosis} sx={{ display: "list-item" }}>
              <Typography variant="h6" sx={{ fontFamily: "Poppins", color: "dimgray" }}>
                {dx.diagnosis} &mdash;{" "}
                <span style={{ color: "maroon", opacity: "0.5" }}>{symptomsForDiagnosis(dx)}</span>
              </Typography>
              {/* {renderBar(index, dx.score)} */}
            </ListItem>
          ))}
      </ol>
    </Box>
  );
};

export default Differentials;
