import { lazy, Suspense, useState } from "react";
import { Route, Routes, useMatch } from "react-router-dom";
import LoadingSpinner from "./components/UI/LoadingSpinner";
import Navbar from "./components/UI/Navbar";

import Home from "./pages/Home";
const AddDiagnosis = lazy(() => import("./components/Diagnosis/AddDiagnosis"));
const AddSymptom = lazy(() => import("./components/Symptom/AddSymptom"));
const Diagnoses = lazy(() => import("./pages/Diagnoses"));
const Symptoms = lazy(() => import("./pages/Symptoms"));

import useFetch from "./hooks/useFetch";
import Terms from "./pages/Terms";

const App = () => {
  const { createResource } = useFetch();
  const [resource, setResource] = useState(createResource());

  const dxMatch = useMatch("/diagnoses/:id");
  const sxMatch = useMatch("/symptoms/:id");

  const matchDiagnosisId = () => {
    return dxMatch ? dxMatch.params.id : null;
  };

  const matchSymptomId = () => {
    return sxMatch ? sxMatch.params.id : null;
  };

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home resource={resource} />} />
        <Route path="/diagnoses" element={<Diagnoses resource={resource.diagnoses} />} />
        <Route
          path="/diagnoses/:id"
          element={<AddDiagnosis resource={resource.symptoms} id={matchDiagnosisId()} />}
        />
        <Route path="/symptoms" element={<Symptoms resource={resource.symptoms} />} />
        <Route path="/symptoms/:id" element={<AddSymptom id={matchSymptomId()} />} />
        {/* <Route path="/terms" element={<Terms resource={resource.symptoms} />} /> */}
      </Routes>
    </Suspense>
  );
};

export default App;
