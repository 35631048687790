import { useState, useEffect, useRef } from "react";
import { Box, Divider, Stack, Typography, Grid } from "@mui/material";

import { Chart, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";

import HomeSymptomSelector from "../components/Symptom/HomeSymptomSelector";
import Differentials from "../components/Diagnosis/Differentials";
import useCalculator from "../hooks/useCalculator";
import TagsBarChart from "../components/Graph/TagsBarChart";

Chart.register(...registerables);

const flattenHashmap = hashMap => {
  return Object.values(hashMap).flatMap(category => Object.values(category));
};

export default function Home({ resource }) {
  const allSymptoms = resource.symptoms.read();
  const allDiagnoses = resource.diagnoses.read();
  const flatDiagnoses = flattenHashmap(allDiagnoses);
  const [differentials, setDifferentials] = useState([]);

  const [differentialsCount, setDifferentialsCount] = useState(5); // Default count is 5

  const sortedSymptomNames = flattenHashmap(allSymptoms)
    .map(sx => sx.medical_term)
    .sort();
  const [symptoms, setSymptoms] = useState([]);

  function updateFields(obj) {
    const updatedSymptoms = obj.symptoms.map(s => s.medical_term);
    setSymptoms(updatedSymptoms);
  }

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item sm={20} md={20} style={{ padding: "10px" }}>
        <Box sx={{ ml: "20px", mt: "2%" }}>
          <HomeSymptomSelector
            symptoms={symptoms}
            symptomList={sortedSymptomNames}
            jsonSymptoms={flattenHashmap(allSymptoms)}
            updateFields={updateFields}
            label="Add Symptom"
            id="symptoms"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        {/* Differential Diagnoses */}
        <Differentials
          symptoms={symptoms}
          flatDiagnoses={flatDiagnoses}
          differentialsCount={differentialsCount}
          setDifferentialsCount={setDifferentialsCount}
          differentials={differentials}
          setDifferentials={setDifferentials}
        />
      </Grid>
      {/* <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontFamily: "inherit", color: "maroon", margin: "20px" }}>
          Differentials by Organ System
        </Typography>
        <TagsBarChart differentials={differentials} />
      </Grid> */}
    </Grid>
  );
}
